import React from 'react';
import { useDisclosure } from '@mantine/hooks';

import { StyledFullHero } from './FullHero.style';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { HomeProps } from '~/pages';
import { CtaParsed } from '../Generic/Cta/CtaParsed';
import { CurvedText } from '../Generic/CurvedText';
import { Modal } from '../Modal/Modal';

import { StyledModal } from './FullHero.style';
import { ContentfulMedia } from '../../types/types';

type GatsbyImageData = GatsbyImageProps['image'];

export const FullHero: React.FC<FullHeroProps> = ({ image, data }) => {
  const [modalOpen, modalHandlers] = useDisclosure(false);

  const modalMedia: Media[] = [];
  const otherMedias: Media[] = [];

  data.media.map((m: ContentfulMedia) =>
    m.title.toLowerCase().includes('modal')
      ? modalMedia.push(m)
      : otherMedias.push(m)
  );

  return (
    <StyledFullHero>
      <GatsbyImage
        image={image.gatsbyImageData as GatsbyImageData}
        alt="Homepage Hero"
        className="bg-img"
      />
      <div className="full-hero--content">
        {data.title && (
          <ShowOnScroll>
            <h1
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
          </ShowOnScroll>
        )}
        {data.description && (
          <ShowOnScroll delay={50}>
            <div
              className="full-hero--description"
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html,
              }}
            />
          </ShowOnScroll>
        )}

        {data.cta && (
          <ShowOnScroll delay={100}>
            <CtaParsed
              className="full-hero--cta"
              raw={data.cta.raw}
              variant="primary"
              size="large"
            />
          </ShowOnScroll>
        )}

        {modalMedia.length && (
          <ShowOnScroll delay={200}>
          <a
            href="#"
            onClick={modalHandlers.open}
            className="modal-cta modal-cta--mobile"
          >
              <CurvedText
                className="curved-text"
                text={'Learn about CBD'}
                fontSize={18}
              />
            {/* <span>{modalMedia[0].label}</span> */}
            <div className="circle">?</div>
          </a>
            </ShowOnScroll>
        )}

        {otherMedias.length && (
          <div className="full-hero--media">
            {otherMedias.map((card, i) => {
              const description = card.description.childMarkdownRemark.html;
              const delay = 150 + i * 50;
              return (
                <ShowOnScroll delay={delay} key={`mediacard-${i}`}>
                  <div className="full-hero--mediacard">
                    <img src={card.media.file.url} />
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                </ShowOnScroll>
              );
            })}
          </div>
        )}
      </div>

      {modalMedia.length && (
        <>
          <a
            href="#"
            onClick={modalHandlers.open}
            className="modal-cta modal-cta--desktop"
          >
            <ShowOnScroll delay={300}>
              <CurvedText
                className="curved-text"
                text={'Learn about CBD'}
                fontSize={18}
              />
            </ShowOnScroll>
            <ShowOnScroll delay={200}>
              {/* <span>{modalMedia[0].label}</span> */}
              <div className="circle">?</div>
            </ShowOnScroll>
          </a>
          <Modal isOpen={modalOpen} onClose={modalHandlers.close}>
            <StyledModal>
              <div
                className="modal--content"
                dangerouslySetInnerHTML={{
                  __html: modalMedia[0].description.childMarkdownRemark.html,
                }}
              />
            </StyledModal>
          </Modal>
        </>
      )}
    </StyledFullHero>
  );
};

interface FullHeroProps {
  image: HomeProps['data']['heroImage'];
  data: HomeProps['data']['page']['hero'];
}

interface Media {
  title?: string;
  label?: string;
  description?: MarkdownData;
  media?: any;
}
