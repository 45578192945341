import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/tools';

export const StyledFullHero = styled.section`
  --min-height: 560px;

  @media (min-width: 768px) {
    --max-height: 900px;
    --min-height: 700px;
  }

  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    min-height: var(--min-height);
    max-height: var(--max-height);
  }

  .full-hero--content {
    min-height: var(--min-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: var(--white);
    text-align: center;
    padding-inline: var(--gutter);
    h1 {
      margin: 0;
      font-size: clamp(${pxtorem(60)}, 6vw, ${pxtorem(90)});
      text-align: center;
      em {
        font-size: clamp(${pxtorem(52)}, 5.06666667vw, ${pxtorem(76)});
      }
    }

    @media (max-width: 767px) {
      margin-block: 30px;

      h1 {
        margin-bottom: var(--gutter);
      }
    }

    @media (min-width: 768px) {
      padding: 120px 0 50px;
    }
  }

  .full-hero--description {
    font-size: ${pxtorem(20)};
    font-weight: 400;

    @media (max-width: 767px) {
      font-size: ${pxtorem(16)};
      margin-bottom: calc(var(--gutter) * 2);
    }
  }

  .full-hero--media {
    display: flex;
    justify-self: end;
    align-self: stretch;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: ${pxtorem(90)};

    @media (max-width: 767px) {
      margin-inline: var(--gutter);
    }
  }

  .full-hero--mediacard {
    font-size: ${pxtorem(12)};
    line-height: 1.12;
    font-weight: 500;
    margin-inline: calc(var(--gutter) / 2);

    p {
      margin: 0;
    }

    @media (min-width: 768px) {
      margin-inline: 0;
      font-size: ${pxtorem(16)};
    }
  }

  .modal-cta {
    --size: 70px;
    position: relative;

    &--desktop {
      position: absolute;
      right: 100px;
      bottom: 40px;
    }

    &--mobile {
      display: inline-flex;
      margin: 40px 0 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    color: var(--coral);

    transform-origin: center;
    transition: transform 150ms ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    .curved-text {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--size);
      height: var(--size);
      transform: translate(-90%, 20%);
      font-weight: 400;
    }

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      background-color: var(--coral);
      color: var(--white);
      font-weight: 500;
      font-size: 20px;
    }

    &--desktop {
      display: none;
    }

    @media (min-width: 768px) {
      &--mobile {
        display: none;
      }
      &--desktop {
        display: block;
      }
    }
  }

  .full-hero--cta {
    min-width: 220px;

    @media (max-width: 767px) {
      padding: 15px 64px;
      font-size: ${pxtorem(16)};
      min-width: 180px;
      margin-bottom: var(--gutter);
    }
  }
`;

export const StyledModal = styled.div`
  height: 100%;
  width: 100%;

  .modal--content {
    h1 {
      margin: 0;
      text-transform: uppercase;
      font: 500 ${pxtorem(12)} / 1.111 var(--primaryFont);
      margin-bottom: 8px;

      @media (min-width: 768px) {
        font-size: ${pxtorem(16)};
      }
    }

    p {
      font: 300 ${pxtorem(28)} / 96% var(--primaryFont);
      margin: 0 0 ${pxtorem(20)};

      @media (min-width: 768px) {
        font-size: ${pxtorem(60)};
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      position: relative;
      font-size: ${pxtorem(16)};
      text-decoration: none;
      transition-property: color 250ms;

      &:after {
        transition: transform 0.25s var(--cubic-ease);
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        height: 1px;
        background: currentColor;
        transform: scaleX(1);
        transform-origin: left center;
      }

      ${desktopHover(`
        background: transparent;
        color: var(--green);

        &:after {
          transform-origin: right center;
          transform: scaleX(0);
        }
    `)}

      @media (min-width: 768px) {
        font-size: ${pxtorem(20)};
        font-weight: 500;
      }
    }
  }

  .modal--link {
    padding-inline: 0;
    width: auto;
  }
`;
