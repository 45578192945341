import React from 'react';
import { HomeProps } from '../../pages/index';

import { StyledHomePage } from './HomePage.style';
import { PageSection, ComponentMapExtraData } from '../PageSection/PageSection';
import { FullHero } from '../FullHero/FullHero';

import { ContentfulPageSection } from '~/types/types';

export const HomePage: React.FC<HomepageProps> = ({
  heroImage,
  hero,
  sections,
}) => {
  const homepageSectionsDefaultData: ComponentMapExtraData = {
    'social-contacts': {
      sectionProps: { bg: 'gray' },
    },
    'shop-by': {
      sectionProps: { bg: 'gray' },
    },
    'text-big': {
      sectionProps: { bg: 'gray' },
    },
    'text-with-blobs': {
      sectionProps: { bg: 'gray' },
    },
    'featured-kits': {
      sectionProps: { bg: 'green' },
    },
    'featured-protocols': {
      sectionProps: { gutter: true },
      wrapperProps: { bg: 'lime' },
    },
    values: {
      sectionProps: { bg: 'gray' },
    },
  };

  return (
    <StyledHomePage>
      {heroImage && hero && <FullHero image={heroImage} data={hero} />}

      {sections &&
        sections.map((section, idx) => {
          if (!section?.sectionType) {
            return null;
          }

          const componentData =
            homepageSectionsDefaultData[section.sectionType.slug] || {};

          return (
            <PageSection
              key={`${section.slug}_${idx}`}
              section={section}
              {...componentData}
            />
          );
        })}
    </StyledHomePage>
  );
};

interface HomepageProps {
  heroImage: HomeProps['data']['heroImage'];
  hero: ContentfulPageSection;
  sections: ContentfulPageSection[];
}
