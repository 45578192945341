import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { HomePage } from '../components/HomePage/HomePage';
import { ContentfulPageSection } from '../types/types';

const IndexPage: React.FC<HomeProps> = ({ data }) => {
  const { heroImage, page } = data;
  return (
    <Layout floatingHeader>
      <Metadata title={page?.name} />
      <HomePage
        heroImage={heroImage}
        hero={page.hero}
        sections={page.sections}
      />
    </Layout>
  );
};

export default IndexPage;

export interface HomeProps {
  data: {
    heroImage: any;
    page: {
      name: string;
      hero: ContentfulPageSection;
      sections: ContentfulPageSection[];
    };
  };
}

export const query = graphql`
  query IndexPage($locale: String) {
    heroImage: contentfulAsset(
      contentful_id: { eq: "3MT0jnXujmFOmYcv4p1TCG" }
    ) {
      gatsbyImageData
    }
    page: contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      name
      hero {
        ...ContentSectionFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`;
