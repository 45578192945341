import react from 'react';
import styled from 'styled-components';

export const CurvedText: React.FC<{
  text: string;
  arc?: number;
  fontSize?: number;
  className: string;
}> = ({ text, arc = 200, fontSize = 20, className }) => {
  const textArray = text.split('');
  return (
    <StyledCurvedText
      className={className}
      textArray={textArray}
      fontSize={fontSize}
      arc={arc}
    >
      <div className="container">
        {textArray.map((letter, i) => (
          <span className={`char-${i}`} key={`char-${i}`}>
            {letter}
          </span>
        ))}
      </div>
    </StyledCurvedText>
  );
};

const StyledCurvedText = styled.div<{
  textArray: string[];
  arc: number;
  fontSize: number;
}>`
  .container {
    transform: rotate(-95deg);
  }

  span {
    height: ${({ arc, fontSize, textArray }) =>
      `${fontSize * (textArray.length / 9) * (360 / arc / 0.9)}px`};
    font-size: ${({ fontSize }) => `${fontSize}px`};
    position: absolute;
    width: ${({ fontSize }) => `${fontSize}px`};
    left: 0;
    top: 0;
    transform-origin: bottom center;
  }

  ${({ textArray, arc }) =>
    textArray.map((letter, i) => {
      return `
      & .char-${i} {
        transform: rotate(${(i * arc) / (textArray.length - 1)}deg);
      }
    `;
    })}
`;
